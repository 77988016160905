import { graphql, StaticQuery } from "gatsby"
import React from "react"
import ArticleCard from "../components/articleCard"
import Layout from "../components/layout"
import Seo from "../components/seo"

const articlesQuery = graphql`
  query {
    articles: allStrapiArticles(
      limit: 3
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          title
          subtitle
          slug
          cover {
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
              caption
              alternativeText
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => (
  <Layout>
    <Seo />
    {/* TODO: Make this a separate component and connect to strapi */}
    <section
      className="align-center"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <header className="main align-center">
        <h1>Change the world, one step at a time.</h1>
        <div style={{ marginBottom: "3em", marginTop: "3em" }}>
          <p style={{ marginBottom: "1em" }}>
            Taking action on climate change doesn't need to be overwhelming.
          </p>
          <p>
            Reinventing.co is your guide to the climate solutinos that matter,
            from electric cars to better homes
          </p>
        </div>
      </header>
      <div className="home-icons" style={{ alignSelf: "center" }}>
        <article>
          <a href="/category/electric-vehicles" className="image">
            <span className="icon solid fa-car"></span>
          </a>
          <h3>Car</h3>
        </article>
        <article>
          <a href="#" className="image">
            <span className="icon solid fa-utensils"></span>
          </a>
          <h3>Food</h3>
        </article>
        <article>
          <a href="#" className="image">
            <span className="icon solid fa-home"></span>
          </a>
          <h3>Home</h3>
        </article>
        <article>
          <a href="#" className="image">
            <span className="icon solid fa-money-bill-wave"></span>
          </a>
          <h3>Money</h3>
        </article>
        <article>
          <a href="#" className="image">
            <span className="icon solid fa-cloud"></span>
          </a>
          <h3>Carbon</h3>
        </article>
        <article>
          <a href="#" className="image">
            <span className="icon solid fa-tshirt"></span>
          </a>
          <h3>Fashion</h3>
        </article>
      </div>
    </section>

    <hr className="major" />

    <div className="row gtr-uniform gtr-200">
      <StaticQuery
        query={articlesQuery}
        render={data => {
          const articles = data.articles.edges
          return articles.map(article => {
            article = article.node
            return <ArticleCard article={article} />
          })
        }}
      />
    </div>

    <hr className="major" />

    <div className="row gtr-uniform">
      <div className="col-8 col-12-medium">
        <h2>The Basics / FAQ</h2>
        <div className="row gtr-uniform">
          <div className="col-6 col-12-small">
            <h4>Lorem ipsum dolor sit amet?</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              consequat arcu ac nisl imperdiet blandit. Nam mi ligula, iaculis
              vel eros vitae, varius imperdiet lacus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Nulla commodo sagittis pellentesque. Ut finibus sit amet
              ex at accumsan.
            </p>
          </div>
          <div className="col-6 col-12-small">
            <h4>Lorem ipsum dolor sit amet?</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              consequat arcu ac nisl imperdiet blandit. Nam mi ligula, iaculis
              vel eros vitae, varius imperdiet lacus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Nulla commodo sagittis pellentesque. Ut finibus sit amet
              ex at accumsan.
            </p>
          </div>
          <div className="col-6 col-12-small">
            <h4>Lorem ipsum dolor sit amet?</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              consequat arcu ac nisl imperdiet blandit. Nam mi ligula, iaculis
              vel eros vitae, varius imperdiet lacus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Nulla commodo sagittis pellentesque. Ut finibus sit amet
              ex at accumsan.
            </p>
          </div>
          <div className="col-6 col-12-small">
            <h4>Lorem ipsum dolor sit amet?</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              consequat arcu ac nisl imperdiet blandit. Nam mi ligula, iaculis
              vel eros vitae, varius imperdiet lacus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Nulla commodo sagittis pellentesque. Ut finibus sit amet
              ex at accumsan.
            </p>
          </div>
        </div>
      </div>

      <div className="col-4 col-12-medium">
        <form method="get" action="/search">
          <h2>Find solutions near you</h2>
          <div className="row gtr-uniform">
            <div className="col-8 col-12-small">
              <input type="hidden" name="mode" value="providers" />
              <input type="text" name="zip" id="zip" placeholder="Zip Code" />
            </div>
            <div className="col-4 col-12-small">
              <input
                type="submit"
                className="button primary fit"
                value="Find"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
)

export default IndexPage
